<template>
  <div
    v-if="showFeedback"
    class="client-feedback-sidebar"
  >
    <button
      v-if="showFeedbackButton"
      class="feedback-button icon-area"
      aria-label="show feedback"
      @click="toggleFeedbackArea"
    >
      <feather-icon type="edit" stroke="white" />
      <span class="button-text">
        Feedback
      </span>
    </button>
    <template v-else>
      <div class="feedback-container">
        <div class="feedback-header-container">
          <feather-icon type="edit" stroke="#363636" />
          <p class="feedback-title">
            Feedback
          </p>
        </div>
        <p class="feedback-details">
          Please share feedback to improve our services. For customer support, call or email us.
        </p>
        <div class="feedback-options-container">
          <b-form-select
            v-model="selectedFeedbackOptionId"
            class="w-100 feedback-options-dropdown float-left"
            @change="toggleClientFeedbackOptionsArea"
          >
            <option :value="null" disabled>Feedback options</option>
            <option
              v-for="clientFeedbackOption in clientFeedbackOptions"
              :key="clientFeedbackOption.id"
              :value="clientFeedbackOption.id"
            >
              {{ clientFeedbackOption.name }}
            </option>
          </b-form-select>
          <div
            v-if="showFeedbackComment"
            class="feedback-options-comment"
          >
            <textarea
              v-model="clientFeedbackOptionComment"
              class="feedback-options-comment-area"
              name="text"
              :maxlength="textAreaCharacterMax"
              placeholder="How can we improve? (Optional)"
              rows="10"
            />
            <div class="character-count">
              <p>
                {{ characterCount }} / {{ textAreaCharacterMax }}
              </p>
            </div>
          </div>
          <div
            v-if="showFeedbackComment"
            class="button-submit-container"
          >
            <button
              class="primary"
              aria-label="submit feedback"
              :disabled="disableSubmitButton"
              @click="submitFeedback"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="button-hide-container"
      >
        <button
          class="button-hide"
          aria-label="hide feedback"
          @click="toggleFeedbackArea"
        >
          <feather-icon type="x" stroke="white" />
        </button>
      </div>
    </template>
  </div>
  <div
    v-else
    class="client-feedback-sidebar"
  >
    <client-feedback-thank-you
      v-if="showThankYou"
      :show-thank-you="showThankYou"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClientFeedbackSidebar',
  components: {
    ClientFeedbackThankYou: () => import('./ClientFeedbackThankYou'),
    FeatherIcon:            () => import('@/components/shared/FeatherIcon'),
  },
  data() {
    return {
      buttonText: 'Submit',
      clientFeedbackSourceCategory: 'step',
      clientFeedbackOptionComment: '',
      selectedFeedbackOptionId: null,
      showFeedbackButton: true,
      showFeedbackComment: false,
      showThankYou: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'showEndOfStageSlide',
    ]),
    ...mapGetters('stagelineClientFeedback', [
      'clientFeedbackOptions',
      'clientFeedbackSources',
      'showClientFeedbackSidebar',
      'textAreaCharacterMax',
      'currentStageId',
      'currentStepId',
    ]),
    characterCount() {
      return this.clientFeedbackOptionComment.length || 0
    },
    disableSubmitButton() {
      return this.buttonText !== 'Submit'
    },
    showFeedback() {
      return !this.showEndOfStageSlide && this.showClientFeedbackSidebar
    },
  },
  watch: {
    currentStageId(newStageId) {
      if (newStageId) this.reset()
    },
    currentStepId(newStepId) {
      if (newStepId) this.reset()
    },
  },
  methods: {
    ...mapActions('stagelineClientFeedback', [
      'saveClientFeedback',
    ]),
    async submitFeedback() {
      this.buttonText = 'Submitting...'
      const params = {
        source_category: this.clientFeedbackSourceCategory,
        options_id: this.selectedFeedbackOptionId,
        liked: false,
        comment: this.clientFeedbackOptionComment,
      }

      await this.saveClientFeedback(params)
      await this.toggleThankYou()
    },
    toggleFeedbackArea() {
      this.showFeedbackButton = !this.showFeedbackButton
    },
    toggleClientFeedbackOptionsArea() {
      this.showFeedbackComment = this.selectedFeedbackOptionId !== null
    },
    async toggleThankYou() {
      this.showThankYou = true
      await new Promise(resolve => setTimeout(() => {
        this.showThankYou = false
        this.buttonText = 'Submit'
        resolve()
      }, 4000))
    },
    reset() {
      this.clientFeedbackOptionComment = ''
      this.selectedFeedbackOptionId = null
      this.showFeedbackButton = true
      this.showFeedbackComment = false
    },
  },
}
</script>

<style scoped lang="scss">
// Palette
$black1: #231F20;
$black1-alpha: #231F2040;
$gray1: #363636;
$teal1: $ct-ui-color-10;
$teal2: $ct-ui-color-26;

// Font Weights
$weight400: 400;
$weight600: 600;

// Typography: Mixins
@mixin typography($font-size: 1.0em, $font-weight: $weight400, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: 1.2;
  color: $color;
}

@mixin font-small($font-weight: $weight400) {
  @include typography(
    $font-size: 0.875em,
    $font-weight: $font-weight
  );
}

// Buttons
// Buttons: Mixin
@mixin btn-hover($hover-color: $teal2, $color: white) {
  border-color: $hover-color;
  background: $hover-color;
  color: $color;
}

@mixin btn-core($padding, $font-size, $hover-color) {
  @include typography($font-weight: $weight600, $color: white);
  width: 100%;
  border-width: 0.0625em;
  border-style: solid;
  border-radius: 0.25em;
  padding: $padding;
  outline: 0;

  &:hover {
    @include btn-hover();
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.6em 2.0em, $font-size: 1.25em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $font-size, $hover-color);
}

// Buttons: Types
button.primary {
  @include btn-default(
    $primary-color: $teal1,
    $hover-color: $teal2
  );
}

// Flexbox
@mixin display-flex($flex-flow: row nowrap, $justify-content: flex-start) {
  display: flex;
  flex-flow: $flex-flow;
  justify-content: $justify-content;
  align-items: center;
}

// Component
.client-feedback-sidebar {
  @include display-flex(
    $flex-flow: column nowrap
  );
  z-index: 1;
  position: fixed;
  bottom: 6%;
  left: 1%;

  .feedback-button {
    @include display-flex(
      $justify-content: space-between
    );
    width: auto;
    margin: 0;
    background: $teal1;
    color: white;
    border-color: $teal1;
    border-radius: 2.5em;
    border-width: 0;
    box-shadow: none;
    outline: none;

    &:hover {
      @include btn-hover();
    }

    span.button-text {
      @include typography(
        $color: white
      );
      padding-left: 0.5em;
    }
  }

  .icon-area {
    min-width: 8.375em;
    padding: 0.5em 1.0em;
  }

  .feedback-container {
    @include display-flex(
      $flex-flow: column nowrap
    );
    width: 17.25em;
    border: 1px solid $black1-alpha;
    border-radius: 0.375em;
    padding: 1.0em;
    color: $black1;
    background: white;

    .feedback-header-container {
      @include display-flex();
      width: 100%;
      padding-bottom: 1.0em;

      .feedback-title {
        @include typography(
          $font-weight: $weight600,
          $color: $gray1
        );
        margin: 0;
        padding: 0.2em 0 0 0.5em;
      }
    }

    .feedback-details {
      @include font-small();
      width: 100%;
      padding-bottom: 1.0em;
    }

    .feedback-options-container {
      padding-bottom: 1.0em;

      .feedback-options-dropdown {
        @include font-small();
        padding-top: 0;
        padding-bottom: 0;
      }

      .feedback-options-comment {
        width: 100%;
        padding-bottom: 1.0em;

        .feedback-options-comment-area {
          @include font-small();
          width: 100%;
          margin-top: 0.5em;
          padding: 0.5em;
          border: 1px solid $black1-alpha;
          border-radius: 0.375em;
        }

        .character-count {
          @include display-flex(
            $justify-content: flex-end
          );

          p {
            @include font-small(
              $font-weight: $weight600
            );
            margin: 0;
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {

        p.feedback-option {
          @include typography(
            $font-size: 0.875em,
            $font-weight: $weight600,
            $color: $black1
          );
          margin: 0;
          padding: 0.25em 0;
        }
      }
    }
  }

  .button-hide-container {
    width: 100%;
    justify-self: flex-start;
    padding-top: 1.0em;
    padding-left: 0.3125em;

    .button-hide {
      @include display-flex($justify-content: center);
      width: 2.5em;
      height: 2.5em;
      margin: 0;
      background: $teal1;
      color: white;
      border-color: $teal1;
      border-radius: 2.5em;
      border-width: 0;
      box-shadow: none;
      outline: none;

      &:hover {
        @include btn-hover();
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .client-feedback-sidebar {
    position: unset;

    .feedback-button {

      .button-text {
        display: none;
      }
    }

    .icon-area {
      min-width: 2.25em;
      min-height: 2.25em;
      padding: 0.8em;

      svg {
        min-width: 1.25em;
        min-height: 1.25em;
      }
    }

    .feedback-container {
      width: 100%;
    }
  }
}
</style>
