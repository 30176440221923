var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFeedback
    ? _c(
        "div",
        { staticClass: "client-feedback-sidebar" },
        [
          _vm.showFeedbackButton
            ? _c(
                "button",
                {
                  staticClass: "feedback-button icon-area",
                  attrs: { "aria-label": "show feedback" },
                  on: { click: _vm.toggleFeedbackArea },
                },
                [
                  _c("feather-icon", {
                    attrs: { type: "edit", stroke: "white" },
                  }),
                  _c("span", { staticClass: "button-text" }, [
                    _vm._v("\n      Feedback\n    "),
                  ]),
                ],
                1
              )
            : [
                _c("div", { staticClass: "feedback-container" }, [
                  _c(
                    "div",
                    { staticClass: "feedback-header-container" },
                    [
                      _c("feather-icon", {
                        attrs: { type: "edit", stroke: "#363636" },
                      }),
                      _c("p", { staticClass: "feedback-title" }, [
                        _vm._v("\n          Feedback\n        "),
                      ]),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "feedback-details" }, [
                    _vm._v(
                      "\n        Please share feedback to improve our services. For customer support, call or email us.\n      "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "feedback-options-container" },
                    [
                      _c(
                        "b-form-select",
                        {
                          staticClass:
                            "w-100 feedback-options-dropdown float-left",
                          on: { change: _vm.toggleClientFeedbackOptionsArea },
                          model: {
                            value: _vm.selectedFeedbackOptionId,
                            callback: function ($$v) {
                              _vm.selectedFeedbackOptionId = $$v
                            },
                            expression: "selectedFeedbackOptionId",
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "" },
                              domProps: { value: null },
                            },
                            [_vm._v("Feedback options")]
                          ),
                          _vm._l(
                            _vm.clientFeedbackOptions,
                            function (clientFeedbackOption) {
                              return _c(
                                "option",
                                {
                                  key: clientFeedbackOption.id,
                                  domProps: { value: clientFeedbackOption.id },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(clientFeedbackOption.name) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _vm.showFeedbackComment
                        ? _c(
                            "div",
                            { staticClass: "feedback-options-comment" },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.clientFeedbackOptionComment,
                                    expression: "clientFeedbackOptionComment",
                                  },
                                ],
                                staticClass: "feedback-options-comment-area",
                                attrs: {
                                  name: "text",
                                  maxlength: _vm.textAreaCharacterMax,
                                  placeholder: "How can we improve? (Optional)",
                                  rows: "10",
                                },
                                domProps: {
                                  value: _vm.clientFeedbackOptionComment,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.clientFeedbackOptionComment =
                                      $event.target.value
                                  },
                                },
                              }),
                              _c("div", { staticClass: "character-count" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.characterCount) +
                                      " / " +
                                      _vm._s(_vm.textAreaCharacterMax) +
                                      "\n            "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.showFeedbackComment
                        ? _c(
                            "div",
                            { staticClass: "button-submit-container" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    "aria-label": "submit feedback",
                                    disabled: _vm.disableSubmitButton,
                                  },
                                  on: { click: _vm.submitFeedback },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.buttonText) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "button-hide-container" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button-hide",
                      attrs: { "aria-label": "hide feedback" },
                      on: { click: _vm.toggleFeedbackArea },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { type: "x", stroke: "white" },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
        ],
        2
      )
    : _c(
        "div",
        { staticClass: "client-feedback-sidebar" },
        [
          _vm.showThankYou
            ? _c("client-feedback-thank-you", {
                attrs: { "show-thank-you": _vm.showThankYou },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }